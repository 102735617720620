<template>
  <div class="index-page">
    <div class="content-box">
      <!-- top -->
      <div class="top-icon">
        <img v-cdnimg v-if="isSignIn" @click="isSignIn = !isSignIn" src="/Account/images/relation/icon_fh.png" alt="">
      </div>
      <!-- img -->
      <div class="img">
        <img v-cdnimg src="/Account/images/relation/image_glzh.png" alt="">
      </div>
      <!-- content -->
      <div class="account">
        <template v-if="!isSignIn">
          <div class="title">关联联想教学教研账号</div>
          <el-input class="username" v-model="userName" placeholder="请输入用户名" clearable></el-input>
          <el-input v-model="passWord" placeholder="请输入密码" clearable type="password" show-password></el-input>
          <div class="footer">
            <el-button type="primary" size="small" @click="onRelation">关联</el-button>
          </div>
          <div class="signin">还没有账号？<span @click="isSignIn = !isSignIn">立即创建</span></div>
        </template>
        <template v-else>
          <div class="row" @click="onGoSignIn">
            <div>
              <img v-cdnimg src="/Account/images/relation/icon_zxcj.png" alt="">
              <span>自行创建</span>
            </div>
            <img v-cdnimg src="/Account/images/relation/icon_jt.png" alt="">
          </div>
          <div class="row" :class="{ 'not-row': showInfo }" @click="showInfo = !showInfo">
            <div>
              <img v-cdnimg src="/Account/images/relation/icon_xtcj.png" alt="">
              <span>系统创建</span>
            </div>
            <img v-cdnimg :class="[showInfo ? 'icon-rotate ' : 'icon-back']" src="/Account/images/relation/icon_jt.png" alt="">
          </div>
          <el-collapse-transition>
            <div class="info-detail" :class="{ 'radius': showInfo }" v-show="showInfo">
              <div class="row-1">系统已为您创建联想教研教学账号</div>
              <div class="row-2">用户名：<span>lx-123456789</span></div>
              <div class="row-2">初始密码：<span>123456</span></div>
              <div class="row-3">
                <el-button type="primary" size="small" @click="onRelationSign">关联并登录</el-button>
              </div>
            </div>
          </el-collapse-transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: [],
  components: {},
  data() {
    return {
      isSignIn: false, // 是否在创建页面
      userName: '',
      passWord: '',
      showInfo: false, // 是否展开显示
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 关联
    onRelation() {
      this.$router.push({
        name: 'unifiedLogin',
        params: {
          type: 'client',
          name: this.userName,
          passWord: this.passWord
        }
      })
    },
    // 自行创建
    onGoSignIn() {
      this.$router.push({
        path: '/Account/SignIn',
        query: {
          type: 'client'
        }
      })
    },
    // 系统创建
    onRelationSign() {
      this.$router.push({
        name: 'unifiedLogin',
        params: {
          type: 'client',
          name: 'lx-123456',
          passWord: '123456'
        }
      })
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.index-page {
  cursor: default;
  width: 100vw;
  height: 100vh;
  background: url('~@Account/images/relation/image_bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 444px;
    height: 576px;
    background: #FFF;
    border-radius: 4px;
    .top-icon {
      height: 16px;
      cursor: pointer;
      padding: 24px;
      text-align: left;
    }
    .img {
      text-align: center;
    }
    .account {
      margin-top: 48px;
      padding: 0 40px;
      .title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        color: #131314;
        margin-bottom: 24px;
      }
      .username {
        margin-bottom: 12px;
      }
      /deep/ .el-input__inner:hover {
        border-color: #FF6A00;
      }
      /deep/ .el-input__inner:focus {
        border-color: #FF6A00;
      }
      .footer {
        margin-top: 12px;
        margin-bottom: 78px;
        display: flex;
        justify-content: space-between;
        .el-button {
          width: 100%;
          font-size: 14px;
        }
        /deep/ .el-button--primary {
          background-color: #FF6A00;
          border-color: #FF6A00;
        }
        /deep/ .el-button--primary:hover {
          background-color: rgba(255, 106, 0, .8);
          border-color: rgba(255, 106, 0, .8);
        }
      }
      .signin {
        text-align: center;
        font-size: 14px;
        color: #909399;
        span {
          cursor: pointer;
          color: #4E82E9;
        }
      }
      /**
       * @params
       * 注册
       */
      .row {
        width: 100%;
        cursor: pointer;
        padding: 0 28px;
        box-sizing: border-box;
        height: 70px;
        background-color: #F5F7FA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          height: 100%;
          span {
            margin-left: 4px;
          }
        }
        &:first-child {
          margin-bottom: 12px;
        }
        .icon-rotate {
          transform: rotateZ(90deg);
          transition: .2s transform;
        }
        .icon-back {
          transform: rotateZ(0deg);
          transition: .2s transform;
        }
      }
      .not-row {
        border-radius: 8px 8px 0 0;
      }
      .info-detail {
        background-color: #F5F7FA;
        padding: 0 24px;
        font-size: 13px;
        .row-1 {
          color: #131314;
        }
        .row-2 {
          color: #606266;
          margin: 6px 0;
          span {
            color: #F15F00;
          }
        }
        .row-3 {
          text-align: right;
          padding-bottom: 20px;
          /deep/ .el-button--primary {
            background-color: #FF6A00;
            border-color: #FF6A00;
          }
          /deep/ .el-button--primary:hover {
            background-color: rgba(255, 106, 0, .8);
            border-color: rgba(255, 106, 0, .8);
          }
        }
      }
      .radius {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
</style>
